<template>
  <RouterLink
    :to="`/${It.id}`"
    class="group"
  >
    <figure>
      <div
        class="relative"
        style="margin-top: 12px"
      >
        <picture class="overflow-hidden rounded-16 block aspect-10/16">
          <!-- <img
            class="object-cover rounded-16"
            :src="It.image_previews.medium"
            alt
          /> -->
          <div
            class="
              object-fill
              min-h-full min-w-full
              bg-no-repeat bg-cover bg-center
              blur-10
              filter
            "
            :style="{
              backgroundImage: `url(${It.image_previews.medium})`,
            }"
          />
          <div
            class="
              object-fill
              min-h-full min-w-full
              bg-no-repeat bg-contain bg-center
              z-1
              absolute
              inset-0
            "
            :style="{
              backgroundImage: `url(${It.image_previews.medium})`,
            }"
          />
        </picture>
        <c-tag
          class="absolute left-4 top-4"
          style="z-index: 1"
        >
          {{ It.type.name }}
        </c-tag>
        <c-tag
          v-if="It.sale_available && It.price_from"
          key="Sale"
          bg="snatch"
          class="absolute right-4"
          style="z-index: 1;margin-top: -40px;"
        >
          {{ It.price_from != It.price_to ?
            `от ${It.price_from} ₽`
            : `цена: ${It.price_from} ₽` }}
        </c-tag>
        <!-- Исключения yabilet-->
        <c-tag
          v-if="
            (!It.sale_available && It.price_from) ||
              (It.yabilet && !It.sale_available && !It.price_from)
          "
          key="WTF"
          :bg="It.price_from == null ? 'seven' : 'snatch' "
          class="absolute right-4"
          style="z-index: 1;margin-top: -40px;"
        >
          {{ It.price_from != It.price_to ?
            It.price_from == null ? 'цена:уточняйте на месте' : `от ${It.price_from} ₽` :
            It.price_from == null ? 'цена:уточняйте на месте' : `цена: ${It.price_from} ₽` }}
        </c-tag>
        <c-tag
          v-if="!It.yabilet && !It.sale_available && !It.price_from"
          key="IsFree"
          bg="seven"
          class="absolute right-4 bottom-4"
          style="z-index: 1"
        >
          Бесплатно
        </c-tag>
        <!-- В закладки -->
        <!--<svg
          width="20"
          height="20"
          style="z-index: 1"
          class="absolute right-4 top-4"
          fill="#FFF"
        >
          <path
            d="M6 2.01023c-.55228 0-1 .45001-1 1.00512V17.986l3.73618-3.0618c.73577-.603 1.79192-.603 2.52762 0L15 17.986V3.01535c0-.55511-.4477-1.00512-1-1.00512H6ZM3 3.01535C3 1.35002 4.34315 0 6 0h8c1.6569 0 3 1.35002 3 3.01535V17.986c0 1.6942-1.9574 2.6286-3.2638 1.558L10 16.4822 6.26382 19.544C4.95737 20.6146 3 19.6802 3 17.986V3.01535Z"
          />
        </svg>-->
      </div>
      <h3
        class="over taleggio mt-4 mb-2 group-hover:text-interstellar"
        @mouseover="isOpen=true"
        @mouseout="isOpen=false"
      >
        <!--              v-tooltip.top-left="{-->
        <!--                content: It.title,-->
        <!--                delay: {hide: 5},-->
        <!--                autoHide: false,-->
        <!--                show: isOpen-->
        <!--              }"-->
        {{ It.title }}
      </h3>
      <div class="mb-2 mozzarella opacity-48">
        {{ It.started_at && `${$dayjs(It.started_at).format("D MMMM")}` }}
        {{
          It.ending_at && ` - ${$dayjs(It.ending_at).format("D MMMM")}`
        }}
      </div>
      <!-- <div class="parmigiano opacity-48 group-hover:opacity-64">
            {{ $dayjs(It.created_at).format("D MMMM YYYY") }}
          </div> -->
      <div
        class="line-clamp-2 mozzarella opacity-48 group-hover:opacity-64"
        v-text="getDescription(It)"
      />
    </figure>
  </RouterLink>
</template>

<script>
import Tag from '@/components/Tag.vue';
import Vue from 'vue';

export default {
  name: 'CardEvent',
  components: {
    CTag: Tag
  },
  props: {
    It: {
      type: Object
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    getDescription(item) {
      return item?.description?.replace(/<\/?[^>]+(>|$)/g, '');
    }
  }
};
</script>

<style>
.over {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.tooltip {
  z-index: 10000;
}
.tooltip-inner {
  display: table;
  max-width: 300px;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding: 8px 8px 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* Разместите всплывающую подсказку */
  padding: 5px 18px 4px;
  z-index: 6666666;
}
</style>
