<template>
  <div
    class="Tag"
    :class="`bg-${bg}`"
  >
    <slot>TEXT</slot>
  </div>
</template>

<script>
// https://stackoverflow.com/questions/50288996/how-to-emit-an-event-from-vue-js-functional-component
export default {
  props: {
    bg: {
      type: String,
      default: 'alien'
    }
  }
};
</script>

<style>
.Tag {
  /* inline-block py-1 bg-alien */
  @apply mozzarella px-3 rounded-full text-white h-6 flex items-center;
}
</style>
