<template>
  <span :class="['tag', { tag__active_free: free } , { tag__active: active }]">{{ title }}</span>
</template>

<script>
export default {
  props: ['title', 'active', 'free']
};
</script>

<style scoped>
.tag {
  display: inline-block;
  position: relative;
  padding: 4px 12px 4px 24px;
  color: #fff;
  background-color: rgba(129, 171, 238, 1);
  white-space: nowrap;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  border-radius: 16px;
  cursor: pointer;
}

.tag:before {
  content: "";
  position: absolute;
  left: 12px;
  top: calc(50% - 4px);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.2;
}

.tag__active_free {
  background-color: #8DC95E !important;
}

.tag__active {
  background-color: rgba(61, 117, 228, 1) !important;
}

.tag__active:before {
  opacity: 1;
}
</style>
