<template functional>
  <div
    class="ListItem"
    :class="{ active: props.active }"
    v-on="listeners"
  >
    <slot>TEXT</slot>
  </div>
</template>

<script>
// https://stackoverflow.com/questions/50288996/how-to-emit-an-event-from-vue-js-functional-component
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
.ListItem {
  @apply cursor-pointer relative pl-4 pr-1 py-3 sulguni opacity-72;
  @apply hover:bg-blue-100;
}

.ListItem.active {
  @apply opacity-100;
  @apply before:(content-[''] absolute left-0 top-0 h-full w-1 bg-rocky);
}
</style>
